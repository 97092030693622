/**
 * Vue axios http client
 * https://github.com/imcvampire/vue-axios#readme
 */

import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

// Set global configs
axios.defaults.baseURL = process.env.BASE_URL;

Vue.use(VueAxios, axios);
