/**
 * Swiper component for Vue.
 * https://github.com/surmon-china/vue-awesome-swiper#readme
 */

import Vue from 'vue';
import {
  Swiper as
  SwiperClass,
  Pagination,
  Navigation,
  Parallax,
} from 'swiper/swiper.esm';
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';

// import style
import 'swiper/swiper-bundle.min.css';

SwiperClass.use([
  Pagination,
  Navigation,
  Parallax,
]);
Vue.use(getAwesomeSwiper(SwiperClass));
