<template>
  <div id="app">

    <!-- navigation START -->
    <app-navigation />
    <!-- navigation END -->

    <!-- view START -->
    <div
      :class="[
        'view' // ,{ 'blue' : $route.name === 'index' }
      ]"
    >
      <transition
        name="fade-slow"
        mode="out-in"
      >
        <router-view />
      </transition>
    </div>
    <!-- view END -->

    <!-- footer START -->
    <app-footer v-if="false" />
    <!-- footer END -->

  </div>
</template>

<script>
export default {
  name: 'MainApp',
  components: {
    AppNavigation: () => import('./components/shared/Navigation.vue'),
    AppFooter: () => import('./components/shared/Footer.vue'),
  },
};
</script>
