/**
 * Ready to use vue components
 * https://element.eleme.io/#/en-US
 */

import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// Import i18n for localization
import i18n from './vue-i18n';

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value),
});
