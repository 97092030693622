/**
 * Vue internationalization plugin
 * https://kazupon.github.io/vue-i18n/
 */

import Vue from 'vue';
import VueI18n from 'vue-i18n';
// Add element-ui locale
import elementLocaleDE from 'element-ui/lib/locale/lang/de';
import elementLocaleEN from 'element-ui/lib/locale/lang/en';

Vue.use(VueI18n);

localStorage.setItem('lang', 'de_AT');

/* eslint camelcase: ["error", {properties: "never"}] */
const locale = localStorage.getItem('lang');

const messages = {
  de_AT: elementLocaleDE,
  en_US: elementLocaleEN,
};

const i18n = new VueI18n({
  locale,
  messages,
});

export default i18n;
