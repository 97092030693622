import Vue from 'vue';
import IdleVue from 'idle-vue';
import store from '../store';

const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 120000, // 2 Minutes (120.000 Milisec.)
  startAtIdle: false,
});
