/**
 * Vue logger plugin
 * https://github.com/justinkames/vuejs-logger#readme
 */

import Vue from 'vue';
import VueLogger from 'vuejs-logger';

// Check current environment
const isProduction = process.env.NODE_ENV === 'production';

// Logger options
const options = {
  isEnabled: !isProduction,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
};

// Use plugin
Vue.use(VueLogger, options);
